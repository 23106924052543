import React from "react";

import { AccordionRichText } from "~components/AccordionRichText";
import { Box } from "~components/Box";
import { Grid } from "~components/Grid";
import { PhoneMockup } from "~components/PhoneMockup";
import { SectionHeader } from "~components/SectionHeader";

import { AnimationSlider } from "~animations/animation_primitives/AnimationSlider";
import { AnimMockUiAccountAggregation } from "~animations/mock_ui/AnimMockUiAccountAggregation";
import { AnimMockUiAccountOverview } from "~animations/mock_ui/AnimMockUiAccountOverview";
import { AnimMockUiAuthentication } from "~animations/mock_ui/AnimMockUiAuthentication";
import { AnimMockUiBankSelection } from "~animations/mock_ui/AnimMockUiBankSelection";
import { AnimMockUiConsent } from "~animations/mock_ui/AnimMockUiConsent";
import { AnimMockUiDeposit } from "~animations/mock_ui/AnimMockUiDeposit";
import { AnimMockUiEcommerce1 } from "~animations/mock_ui/AnimMockUiEcommerce1";
import { AnimMockUiEcommerce2 } from "~animations/mock_ui/AnimMockUiEcommerce2";
import { AnimMockUiEcommerceRefund1 } from "~animations/mock_ui/AnimMockUiEcommerceRefund1";
import { AnimMockUiEcommerceRefund2 } from "~animations/mock_ui/AnimMockUiEcommerceRefund2";
import { AnimMockUiLoading } from "~animations/mock_ui/AnimMockUiLoading";
import { AnimMockUiSignup2 } from "~animations/mock_ui/AnimMockUiSignup2";
import { AnimMockUiSuccess } from "~animations/mock_ui/AnimMockUiSuccess";
import { AnimMockUiVerification1 } from "~animations/mock_ui/AnimMockUiVerification1";
import { AnimMockUiVrp1 } from "~animations/mock_ui/AnimMockUiVrp1";
import { AnimMockUiVrp2 } from "~animations/mock_ui/AnimMockUiVrp2";
import { AnimMockUiWithdrawal } from "~animations/mock_ui/AnimMockUiWithdrawal";

import {
  SequencedAnimationsContext,
  SequencedAnimationsContextProvider,
} from "~context/SequencedAnimationsContext";

import type { AccordionAnimationItemProps } from "~animations/animation_primitives/AccordionAnimationItem";
import type { SectionHeaderProps } from "~components/SectionHeader";
import type { StoryblokBlok } from "~types/storyblok.types";

export const ANIMATIONS = {
  AnimMockUiEcommerce1,
  AnimMockUiEcommerce2,
  AnimMockUiBankSelection,
  AnimMockUiAuthentication,
  AnimMockUiSuccess,
  AnimMockUiEcommerceRefund1,
  AnimMockUiAccountAggregation,
  AnimMockUiEcommerceRefund2,
  AnimMockUiAccountOverview,
  AnimMockUiWithdrawal,
  AnimMockUiLoading,
  AnimMockUiVrp1,
  AnimMockUiVrp2,
  AnimMockUiConsent,
  AnimMockUiDeposit,
  AnimMockUiSignup2,
  AnimMockUiVerification1,
} as const;

export interface AccordionHowItWorksProps extends StoryblokBlok {
  isScrollable?: boolean;
  items: Array<Omit<AccordionAnimationItemProps, "index"> & StoryblokBlok>;
  sectionHeader?: Array<StoryblokBlok & SectionHeaderProps>;
}

const GRID_TEMPLATE_COLUMNS = {
  mobile: 1,
  desktop: 2,
} as const;

export function AccordionHowItWorks({
  items,
  sectionHeader: sectionHeaderBlokArray,
  ...rest
}: AccordionHowItWorksProps) {
  const [sectionHeader] = sectionHeaderBlokArray || [];

  return (
    <SequencedAnimationsContextProvider
      length={items?.length}
      orientation="horizontal"
    >
      <SequencedAnimationsContext.Consumer>
        {({ current, previous, handleUserSetState }) => {
          return (
            <Grid
              gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
              alignItems="start"
              {...rest}
            >
              {/**
               * ----------------------------------------------
               * Main content block
               * includes
               * - section header
               * - accordion controller for animations carousel
               * ----------------------------------------------
               */}
              <Box>
                {sectionHeader && (
                  <SectionHeader
                    marginBottom="sectionRowGap"
                    {...sectionHeader}
                  />
                )}
                <AccordionRichText
                  value={current?.toString()}
                  onValueChange={handleUserSetState}
                  accordionItems={items}
                  collapsible={false}
                  shouldShowNumbers
                />
              </Box>

              {/**
               * ----------------------------------------------
               * Video slider
               * - renders a animation corresponding to the active accordion item
               * - transitions animations using Carousel
               * ----------------------------------------------
               */}

              {Array.isArray(items) && (
                <PhoneMockup
                  marginLeft="auto"
                  position="relative"
                  display={{ mobile: "none", desktop: "block" }}
                  aspectRatio="mobile"
                  justifyContent="center"
                  {...rest}
                >
                  <AnimationSlider
                    animationComponentMap={ANIMATIONS}
                    items={items}
                    current={current}
                    previous={previous}
                  />
                </PhoneMockup>
              )}
            </Grid>
          );
        }}
      </SequencedAnimationsContext.Consumer>
    </SequencedAnimationsContextProvider>
  );
}
